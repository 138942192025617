import { useState, useRef } from 'react';
import Navbar from './Navbar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format, addDays } from 'date-fns';
import emailjs from 'emailjs-com';
import Axios from 'axios';

function App() {
  const [sent, setSent] = useState(false);
  const [name, setName] = useState('');
  const [claim, setClaim] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [enquiry, setEnquiry] = useState('');
  const [location, setLocation] = useState('');
  const [scope, setScope] = useState('');
  const [company, setCompany] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [number, setNumber] = useState('');

  const [date, setDate] = useState(new Date());
  const dateFormatted = format(date, "yyyy/MM/dd"); 
  const dateSelected = date =>{
    date.toLocaleDateString();
    setDate(date);
  };

  const [enquiryType, setEnquiryType] = useState('0');
  const handleSelect = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute('id');

    setEnquiryType(optionElementId);
  }
  
  const singleDay = addDays(date, 1);
  const doubleDays = addDays(date, 2);

  const calcDays = () => {
    if (enquiryType === '0'){
      return new Date();
    } else if (enquiryType === '1'){
      return singleDay;
    } else if (enquiryType === '2'){
      return doubleDays;
    } 
  }

  const form = useRef();

  const handleSubmit = (e) => {

    emailjs.sendForm('service_tydvgsc', 'template_yr66mio', form.current, 'user_dRXsFPpBETmXoz31uPclY')
      .then((result) => {
          console.log(result.text);
          setSent(true);
      }, (error) => {
          console.log(error.text);
      });
  
    Axios.post("https://waidler-booking-portal.herokuapp.com/api/insert", {
      dbClaim: claim,
      dbName: name, 
      dbEmail: email, 
      dbPhone: phone,
      dbEnquiry: enquiry, 
      dbLocation: location,
      dbScope: scope, 
      dbCompany: company,
      dbStreet: street, 
      dbCity: city,
      dbNumber: number,
      dbDate: dateFormatted,
    }).then(() => {
      alert("Booking successful!");
    });
  }

  return (
    <div className="App">
    <Navbar />
      <div className="content">
        <h1>Booking Portal</h1>
        <div className="bookingForm">
            <h2>Book Inspection</h2>
            <form method="POST" ref={form} onSubmit={handleSubmit}>
            <label>Enter claim number:</label>
            <input type="text" value={claim} name="claim" onChange={(e) => setClaim(e.target.value)} required/>
            <label>Your name:</label>
            <input type="text" value={name} name="name" onChange={(e) => setName(e.target.value)} required/>
            <label>Email address:</label>
            <input type="email" value={email} name="email" onChange={(e) => setEmail(e.target.value)} required/>
            <label>Phone number:</label>
            <input type="text" value={phone} name="phone" onChange={(e) => setPhone(e.target.value)} required/>
            <label>Enquiry type:</label>
            <select value={enquiry} name="enquiry" onChange={handleSelect} onInput={(e) => setEnquiry(e.target.value)} required>
              <option id="0">Select Enquiry Type...</option>
              <option value="Pre-investigation Assessment" id="0">Pre-investigation Assessment</option>
              <option value="Desktop Assessment" id="0">Desktop Assessment</option>
              <option value="Damage Assessment" id="1">Damage Assessment</option>
              <option value="Post-Repair Quality Assessment" id="1">Post-Repair Quality Assessment</option>
              <option value="Mobile Auto Electrician" id="1">Mobile Auto Electrician</option>
              <option value="Vehicle Fluid Analysis" id="1">Vehicle Fluid Analysis</option>
              <option value="Technical Inspection" id="1">Technical Inspection</option>
              <option value="Condition Inspection" id="1">Condition Inspection</option>
              <option value="Vehicle Fire Investigation" id="2">Vehicle Fire Investigation</option>
              <option value="Vehicle Theft Investigation" id="2">Vehicle Theft Investigation</option>
              <option value="Water defect investigation" id="2">Water defect investigation</option>
              <option value="Technical Investigation" id="2">Technical Investigation</option>
              <option value="Collision Investigation" id="2">Collision Investigation</option>
            </select>
            <label>Vehicle location:</label>
            <select value={location} name="location" onChange={(e) => setLocation(e.target.value)} required>
              <option>Province...</option>
              <option value="Gauteng">Gauteng</option>
              <option value="KwaZulu Natal">KwaZulu Natal</option>
              <option value="Freestate">Freestate</option>
              <option value="Limpopo">Limpopo</option>
              <option value="Mpumalanga">Mpumalanga</option>
              <option value="North West">North West</option>
              <option value="Western Cape">Western Cape</option>
              <option value="Eastern Cape">Eastern Cape</option>
              <option value="Northern Cape">Northern Cape</option>
            </select>
            <label>Scope:</label>
            <textarea value={scope} name="scope" onChange={(e) => setScope(e.target.value)}  placeholder="Your specific needs..." required></textarea>
            <label>Vehicle location:</label>
            <input type="text" value={company} name="company" onChange={(e) => setCompany(e.target.value)} placeholder="Company name" required/>
            <input type="text" value={street} name="street" onChange={(e) => setStreet(e.target.value)}  placeholder="Street address" required/>
            <input type="text" value={city} name="city" onChange={(e) => setCity(e.target.value)}  placeholder="City" required/>
            <input type="text" value={number} name="number" onChange={(e) => setNumber(e.target.value)}  placeholder="Contact name & number" required/>
            <div>
              <label>Attatchments to be sent via email.</label>
            </div>
            <div className="calendar" name="selectedDate">
            <Calendar
            name="selectedDate"
            tileDisabled={({ date }) => date.getDay() === 0 || date.getDay() === 6}
            prev2AriaLabel={null}
            prev2Label={null}
            next2Label={null}
            locale={null}
            showNeighboringMonth={false}
            minDetail="month"
            minDate={calcDays()}
            value={date}
            onClickDay={dateSelected}
            //onChange={dateSelected} 
            />
            <input type="text" value={dateFormatted} name="formattedDate" readOnly/>
            </div>
            { !sent ? (
              <div>
              <button className="btnSubmit">Book</button>
              </div>
            ) : <label>Booking successful!</label>}
            </form>       
        </div>
      </div>
      <div className="disclaimer">
      <h2>**Disclaimer**</h2>
            <p>
              Clients are liable for the accuracy of the information provided.<br/>
              Waidler (Pty) Ltd does not accept liability for inaccurate data provided through this portal.
            </p>
            <h2>**Download supporting documentation**</h2>
            <p>
              <a href="https://www.waidler.co.za/main/Client_Obligations.pdf">Client obligations</a><br/>
              <a href="https://www.waidler.co.za/main/Consent_Letter.pdf">Consent letter</a><br/>
              <a href="https://www.waidler.co.za/main/Terms_Conditions.pdf">Terms & conditions</a><br/>
            </p>
            </div>
    </div>
  );
}

export default App;
